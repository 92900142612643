import React from "react";
import { Flex, Group, Text } from "@mantine/core";
import { format } from "date-fns";
import Section from "../Section";

type InspectionProps = {
	lastInspection: string | null;
	route: string | null;
};

function Inspection({ lastInspection, route }: InspectionProps) {
	return (
		<Section title="Begehung">
			<Flex rowGap={6} columnGap={56} wrap="wrap">
				<Group gap={8} wrap="nowrap" flex="1 0 auto">
					<Text size="sm">Letzte Begehung:</Text>
					<Text size="sm">
						{lastInspection ? (
							<>
								{format(new Date(lastInspection), `dd.MM.yyyy `)}
								<Text fs="italic" component="span">
									{format(new Date(lastInspection), `HH:mm`)}
								</Text>
							</>
						) : (
							"-"
						)}
					</Text>
				</Group>
				<Group gap={8} flex="1 0 auto">
					<Text size="sm">Route:</Text>
					{route ? <Text size="sm">{route}</Text> : "-"}
				</Group>
			</Flex>
		</Section>
	);
}
export default Inspection;
