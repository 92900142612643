import React, { ReactElement } from "react";
import { Card, Button, Row, Typography } from "antd";
import { useAccount, useMsal } from "@azure/msal-react";
import { Col } from "antd/lib";
import { Text } from "@mantine/core";
import styles from "./index.module.scss";
import useGetUserRoles from "../../utilities/client/hooks/useGetUserRoles";
import { mapUserRoleToString } from "../../utilities/client/roles.util";

export default function UserProfile(): ReactElement {
	const { instance } = useMsal();
	const account = useAccount();
	const userRoles = useGetUserRoles();

	return (
		<>
			<Row justify="center" className={styles.container}>
				<Row style={{ width: 500 }}>
					<Col span={24}>
						<Typography.Title level={3} className={styles.title}>
							Profil
						</Typography.Title>
					</Col>
					<Col span={24}>
						<Card type="inner" title="Name" className={styles.card}>
							{account?.name}
						</Card>
					</Col>
					<Col span={24}>
						<Card type="inner" title="Rolle" className={styles.card}>
							{userRoles.map((role) => mapUserRoleToString(role)).join(", ")}
						</Card>
					</Col>
					<Col span={24}>
						<Button
							type="primary"
							onClick={() => instance.logoutRedirect()}
							className={styles["logout-btn"]}
						>
							Abmelden
						</Button>
					</Col>
				</Row>
			</Row>
			{process.env.REACT_APP_MONOREPO_VERSION && (
				<Text className={styles.version}>
					{process.env.REACT_APP_MONOREPO_VERSION}
					{process.env.REACT_APP_STAGE_NAME_SUFFIX}
				</Text>
			)}
		</>
	);
}
