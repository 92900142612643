import React, { ReactElement, useMemo } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconExclamationCircleFilled } from "@tabler/icons-react";
import {
	postStation,
	Station,
	StationStateAdd,
} from "../../utilities/api/jelbi-dashboard-api";
import stationService from "../../services/stationService";
import StationForm from "./StationForm";
import { StationFormSubmitValues } from "./utils/station-form-types";
import useStations from "../../utilities/client/hooks/useStations";

function AddStation(): ReactElement {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { data: stations } = useStations();
	const uniqueRoutes = useMemo(() => {
		if (!stations?.length) {
			return [];
		}

		const sortedRoutes = stations
			.map((station) => station.route)
			.filter((route) => route !== "")
			.sort();
		return [...new Set(sortedRoutes)];
	}, [stations]);

	const addStationMutation = useMutation({
		mutationFn: async (newStation: StationFormSubmitValues) => {
			const response = await postStation({
				...newStation,
				state: newStation.state as StationStateAdd,
			});

			if (response.status !== 201) {
				return Promise.reject();
			}
			return Promise.resolve(response.data);
		},
		onSuccess: (addedStation: Station) => {
			notifications.show({
				id: "successAddStation",
				position: "top-center",
				message: `Station ${addedStation.name} hinzugefügt!`,
				color: "green",
				icon: <IconCheck />,
			});
			stationService.updateQueryCache(queryClient, addedStation);
			navigate(`/stations/${addedStation.id}`);
		},
		onError: () => {
			notifications.show({
				id: "failAddStation",
				position: "top-center",
				message: "Station konnte nicht hinzugefügt werden!",
				color: "red",
				icon: <IconExclamationCircleFilled />,
			});
		},
	});

	return (
		<StationForm
			onSubmit={addStationMutation.mutate}
			uniqueRoutes={uniqueRoutes}
		/>
	);
}

export default AddStation;
