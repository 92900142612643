import React, { ReactElement, useState } from "react";
import { Pagination, Text } from "@mantine/core";
import {
	DataTable,
	DataTableColumn,
	DataTableRowClickHandler,
} from "mantine-datatable";
import chunk from "./chunk";
import { TABLE_RECORDS_PER_PAGE } from "../../constants";
import classes from "./Table.module.scss";
import useIsMobileView from "../../utilities/client/hooks/useIsMobileView";

type TableProps<T> = {
	columns: DataTableColumn<T>[];
	records: T[];
	recordsPerPage?: number;
	onRowClick?: DataTableRowClickHandler<T>;
	isPaginationShown?: boolean;
};

function Table<T>({
	columns,
	records,
	recordsPerPage = TABLE_RECORDS_PER_PAGE,
	onRowClick,
	isPaginationShown = true,
}: TableProps<T>): ReactElement {
	const isMobileView = useIsMobileView();

	const [page, setPage] = useState(1);

	const tableChunks = chunk(records, recordsPerPage);

	return (
		<>
			<DataTable
				classNames={{ header: classes.header }}
				minHeight={150}
				columns={columns}
				records={isPaginationShown ? tableChunks[page - 1] : records}
				emptyState={<Text>Keine Daten gefunden</Text>}
				withTableBorder={false}
				onRowClick={onRowClick ?? undefined}
			/>
			{isPaginationShown && (
				<Pagination
					className={classes.pagination}
					total={tableChunks.length}
					value={page}
					onChange={setPage}
					boundaries={isMobileView ? 0 : 1}
				/>
			)}
		</>
	);
}

export default Table;
