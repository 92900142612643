import React, { ReactElement, useState, useEffect } from "react";
import { ActionIcon, Modal, SimpleGrid, Text } from "@mantine/core";

import { readLocalStorageValue, useDisclosure } from "@mantine/hooks";
import { ReactComponent as FilterIconInactive } from "../../../../../assets/map-icons/filter-line.svg";
import { ReactComponent as FilterIconActive } from "../../../../../assets/map-icons/filter-solid.svg";
import useStations from "../../../../../utilities/client/hooks/useStations";
import MapFilterDialog from "./MapFilterDialog/MapFilterDialog";
import { MapFilterOptions, defaultMapFilters } from "./MapFilterDialog/helper";
import { LOCAL_STORAGE_KEY_MAP_FILTERS } from "../../../../../utilities/client/local-storage-keys";
import { areFlatObjectsEqual } from "../../../../../utilities/client/general-helpers";

type FilterButtonProps = {
	callbackFunctions: {
		setMapFilters: (filterOptions: MapFilterOptions) => void;
		setStationSearch: (stationId: string) => void;
	};
	mapFilters: MapFilterOptions;
};

function FilterButton({
	callbackFunctions,
	mapFilters,
}: FilterButtonProps): ReactElement {
	const [opened, { open, close }] = useDisclosure(false);
	const [isMapFilterActive, setIsMapFilterActive] = useState(false);
	const { data: stations } = useStations();

	// initial filter load
	useEffect(() => {
		if (
			!areFlatObjectsEqual(
				readLocalStorageValue({ key: LOCAL_STORAGE_KEY_MAP_FILTERS }),
				defaultMapFilters
			)
		) {
			setIsMapFilterActive(true);
		}
	}, []);

	useEffect(() => {
		if (
			areFlatObjectsEqual(
				readLocalStorageValue({ key: LOCAL_STORAGE_KEY_MAP_FILTERS }),
				defaultMapFilters
			)
		) {
			setIsMapFilterActive(false);
		}
	}, [mapFilters]);

	return (
		<>
			<ActionIcon
				radius="xl"
				bg="#fff"
				bd="1.5px solid"
				variant="outline"
				color="var(--mantine-primary-color-6)"
				aria-label="Filter"
				title="Filter"
				onClick={opened ? close : open}
				style={{ borderRadius: "22px", borderWidth: "1.5px" }}
			>
				{isMapFilterActive ? (
					<FilterIconActive
						style={{
							width: "28px",
							height: "28px",
							fill: "var(--mantine-primary-color-6)",
						}}
					/>
				) : (
					<FilterIconInactive
						style={{
							width: "28px",
							height: "28px",
							fill: "var(--mantine-primary-color-6)",
						}}
					/>
				)}
			</ActionIcon>

			<Modal
				title={
					<Text component="h2" fw="bold" pl={24}>
						Filter
					</Text>
				}
				centered
				opened={opened}
				onClose={close}
				w={1000}
			>
				<SimpleGrid p="24px 24px">
					<MapFilterDialog
						callbackFunctions={{
							setMapFilters: callbackFunctions.setMapFilters,
							setStationSearch: callbackFunctions.setStationSearch,
							closeModal: close,
							setIsMapFilterActive,
						}}
						stations={stations || []}
						isMapFilterActive={isMapFilterActive}
						mapFilters={mapFilters}
					/>
				</SimpleGrid>
			</Modal>
		</>
	);
}

export default FilterButton;
