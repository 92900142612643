import {
	Issue,
	Station,
} from "../../../../../../utilities/api/jelbi-dashboard-api";

export type StationTypeFilterOption =
	| "showAllTypes"
	| "showPoints"
	| "showStations";

export type IssueFilters = {
	// issues
	showDamageIssues: boolean;
	showDefectIssues: boolean;
	showPollutionIssues: boolean;
	showTrafficSafetyIssues: boolean;
	showOtherIssues: boolean;
	[key: string]: boolean | string;
};

export type MapFilterOptions = {
	// route
	route: string;
	// type
	stationTypeFilterOption: StationTypeFilterOption;
} & IssueFilters;

export const getFilteredStations = (
	stations: Station[],
	issues: Issue[],
	filters: MapFilterOptions
): Station[] => {
	const isFilteredForIssues =
		filters.showDamageIssues ||
		filters.showDefectIssues ||
		filters.showPollutionIssues ||
		filters.showTrafficSafetyIssues ||
		filters.showOtherIssues;

	let isStationTypeFilterActive = false;
	if (
		filters.stationTypeFilterOption === "showStations" ||
		filters.stationTypeFilterOption === "showPoints"
	) {
		isStationTypeFilterActive = true;
	}

	let isRouteFilterActive = false;
	if (filters.route !== "") {
		isRouteFilterActive = true;
	}

	const filteredStationIdsByIssues = new Set<string>();
	if (isFilteredForIssues) {
		issues.forEach((issue) => {
			if (
				(filters.showDamageIssues && issue.problem === "Beschädigung") ||
				(filters.showDefectIssues && issue.problem === "Defekt") ||
				(filters.showPollutionIssues && issue.problem === "Verschmutzung") ||
				(filters.showTrafficSafetyIssues &&
					issue.problem === "Verkehrssicherheit") ||
				(filters.showOtherIssues && issue.problem === "Sonstiges")
			) {
				filteredStationIdsByIssues.add(issue.station.id);
			}
		});
	}

	const filteredStations = isFilteredForIssues
		? filteredStationIdsByIssues
		: new Set<string>(stations.map(({ id }) => id));
	if (isStationTypeFilterActive || isRouteFilterActive) {
		stations.forEach((station) => {
			if (
				(isStationTypeFilterActive &&
					filters.stationTypeFilterOption === "showPoints" &&
					!station.isPoint) ||
				(filters.stationTypeFilterOption === "showStations" && station.isPoint)
			) {
				filteredStations.delete(station.id);
			}
			if (isRouteFilterActive && filters.route !== station.route) {
				filteredStations.delete(station.id);
			}
		});
	}

	return stations.filter(({ id }) => filteredStations.has(id));
};

export const getStationTextSearchResult = (
	searchedStationId: string,
	stations: Station[]
): Station | undefined =>
	stations.find((station) => station.id === searchedStationId);

export const transformStationsToStationFilterOptions = (stations: Station[]) =>
	stations.map((station) => {
		return {
			key: station.id,
			value: `${station.shortName} ${station.name}`,
		};
	});

export const transformStationsToRouteFilterOptions = (stations: Station[]) => {
	const sortedRoutes = stations
		.filter((station) => station.route !== "")
		.map((station) => station.route)
		.sort();
	return [...new Set(sortedRoutes)].map((route) => {
		return { key: route, value: route };
	});
};

export const setIssueFilterOption = (
	selectedIssueFilters: string[],
	mapFilters: MapFilterOptions,
	setMapFilters: (filterOptions: MapFilterOptions) => void,
	setIsMapFilterActive: (isActive: boolean) => void
) => {
	const newMapFilters = { ...mapFilters };

	Object.keys(mapFilters).forEach((prop) => {
		if (selectedIssueFilters.includes(prop)) {
			newMapFilters[prop] = true;
		} else if (typeof mapFilters[prop] === "boolean") {
			newMapFilters[prop] = false;
		}
	});

	setMapFilters(newMapFilters);
	setIsMapFilterActive(true);
};

export const setStationTypeFilterOption = (
	selectedOption: string,
	mapFilters: MapFilterOptions,
	setMapFilters: (filterOptions: MapFilterOptions) => void,
	setIsMapFilterActive: (isActive: boolean) => void
) => {
	setMapFilters({
		...mapFilters,
		stationTypeFilterOption: selectedOption as StationTypeFilterOption,
	});
	setIsMapFilterActive(true);
};

export const defaultMapFilters: MapFilterOptions = {
	showDamageIssues: false,
	showDefectIssues: false,
	showOtherIssues: false,
	showPollutionIssues: false,
	showTrafficSafetyIssues: false,
	route: "",
	stationTypeFilterOption: "showAllTypes" as StationTypeFilterOption,
};
