// disable eslint for using props spreading as it's best practices in https://mantine.dev/form/use-form/
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from "react";
import {
	Title,
	TextInput,
	Group,
	Stack,
	Textarea,
	Select,
	Divider,
	Text,
	Checkbox,
	Button,
	InputLabel,
	Grid,
	Flex,
	Autocomplete,
} from "@mantine/core";
import {
	TransformedValues,
	UseFormReturnType,
	isNotEmpty,
	useForm,
} from "@mantine/form";
import { Link } from "react-router-dom";
import { Station, StationState } from "../../utilities/api/jelbi-dashboard-api";
import districtOptions from "../../utilities/client/districts";
import stationStateOptions from "../../utilities/client/station-state";
import classes from "./StationForm.module.scss";
import useGetUniqueStationShortNames from "../../utilities/client/hooks/useGetUniqueStationShortNames";
import StationFormValidationErrorMessages from "./utils/StationFormValidationErrorMessages";
import {
	getInitialValues,
	getTransformedValues,
	STATION_EQUIPMENT_OPTIONS,
} from "./utils/station-form-helper";
import {
	StationFormSubmitValues,
	StationFormValues,
} from "./utils/station-form-types";
import validators from "./utils/station-form-validators";
import StationVehiclesInput from "./StationVehiclesInput";
import PlannedClosuresListInput from "./PlannedClosuresListInput";
import useIsMobileView from "../../utilities/client/hooks/useIsMobileView";

type StationFormProps = {
	onSubmit: (station: StationFormSubmitValues) => void;
	station?: Station;
	uniqueRoutes: string[];
};

function StationForm({
	onSubmit,
	station,
	uniqueRoutes,
}: StationFormProps): ReactElement {
	const isMobile = useIsMobileView();

	const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
	const [isRouteFieldSelected, setIsRouteFieldSelected] = useState(false);

	const [isPlannedClosureCheckboxEnabled, setIsPlannedClosureCheckboxEnabled] =
		useState(
			station
				? station.plannedClosures.enabled ||
						station.state === StationState.Active
				: false
		);
	const [isPlannedClosureEnabled, setIsPlannedClosureEnabled] = useState(
		station ? station.plannedClosures.enabled : false
	);

	const uniqueStationShortNames = useGetUniqueStationShortNames();

	const form: UseFormReturnType<StationFormValues> = useForm<StationFormValues>(
		{
			mode: "uncontrolled",
			validateInputOnChange: true,
			initialValues: getInitialValues(station),
			validate: {
				name: isNotEmpty(StationFormValidationErrorMessages.NAME_MISSING),
				shortName: (value) =>
					validators.validateStationShortName(
						value,
						station ? station.shortName : undefined,
						uniqueStationShortNames
					),
				address: isNotEmpty(StationFormValidationErrorMessages.ADDRESS_MISSING),
				district: isNotEmpty(
					StationFormValidationErrorMessages.DISTRICT_MISSING
				),
				geolocationCoordinates: validators.validateStationLocation,
				geofenceCoordinates: validators.validateStationGeofence,
				state: isNotEmpty(StationFormValidationErrorMessages.STATE_MISSING),
				plannedClosures: {
					enabled: (value) =>
						validators.validatePlannedClosuresAmount(
							value,
							form.getValues().plannedClosuresList
						),
				},
				plannedClosuresList: {
					start: (value, _, fieldLabel) =>
						validators.validateStationPlannedClosureStart(
							value,
							form.getValues().plannedClosuresList,
							fieldLabel
						),
					end: (value, _, fieldLabel) =>
						validators.validateStationPlannedClosureEnd(
							value,
							form.getValues().plannedClosuresList,
							fieldLabel
						),
				},
			},
			transformValues: getTransformedValues,
		}
	);

	useEffect(() => {
		if (form.isValid() && form.isDirty()) {
			setIsSaveButtonEnabled(true);
		} else {
			setIsSaveButtonEnabled(false);
		}
	}, [form]);

	return (
		<>
			<Title className={classes.heading} order={3}>
				{station ? "Station ändern" : "Neue Station hinzufügen"}
			</Title>
			<form
				className={classes.container}
				onSubmit={form.onSubmit((values: TransformedValues<typeof form>) =>
					onSubmit(values as StationFormSubmitValues)
				)}
			>
				<Grid gutter={28}>
					<Grid.Col span={{ base: 12, lg: 6 }}>
						<TextInput
							key={form.key("name")}
							{...form.getInputProps("name")}
							label="Standortname"
							withAsterisk
							placeholder="Bitte gib einen Namen an"
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<TextInput
							key={form.key("shortName")}
							{...form.getInputProps("shortName")}
							label="Kurzbezeichnung"
							withAsterisk
							placeholder="Bitte gib eine Kurzbezeichnung an"
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<Autocomplete
							key={form.key("route")}
							{...form.getInputProps("route")}
							label="Route"
							placeholder={
								isRouteFieldSelected ? "" : "Bitte gib eine Route an"
							}
							data={uniqueRoutes}
							onClick={() => setIsRouteFieldSelected(true)}
							onBlur={(event) => {
								form.setFieldValue(
									"route",
									event.currentTarget.value.toUpperCase()
								);
								setIsRouteFieldSelected(false);
							}}
							styles={() => ({
								input: {
									textTransform: isRouteFieldSelected ? "uppercase" : "none",
								},
							})}
						/>
					</Grid.Col>

					<Grid.Col span={12}>
						<Textarea
							key={form.key("description")}
							{...form.getInputProps("description")}
							label="Beschreibung (max. 255 Zeichen)"
							maxLength={255}
							autosize
							placeholder="Bitte gib eine Beschreibung an"
						/>
					</Grid.Col>

					<Grid.Col span={12}>
						<Divider />
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<Textarea
							key={form.key("address")}
							{...form.getInputProps("address")}
							label="Adresse"
							maxLength={255}
							withAsterisk
							autosize
							placeholder="Bitte gib eine Adresse an"
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<Select
							key={form.key("district")}
							{...form.getInputProps("district")}
							label="Bezirk"
							withAsterisk
							allowDeselect={false}
							placeholder="Bitte wähle einen Bezirk aus"
							data={districtOptions}
						/>
					</Grid.Col>

					<Grid.Col span={12}>
						<Divider />
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<TextInput
							key={form.key("geolocationCoordinates")}
							{...form.getInputProps("geolocationCoordinates")}
							label="Standpunkt des Standorts"
							withAsterisk
							placeholder="Bitte gib die Geokoordinaten an"
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<Textarea
							key={form.key("geofenceCoordinates")}
							{...form.getInputProps("geofenceCoordinates")}
							label="Geographische Begrenzung des Standorts"
							withAsterisk
							autosize
							placeholder="Bitte gib eine geographische Begrenzung an"
						/>
					</Grid.Col>

					<Grid.Col span={12}>
						<Divider />
					</Grid.Col>

					<Grid.Col span={12}>
						<InputLabel>Ausstattung</InputLabel>
						<Group gap="xl" justify={isMobile ? "space-between" : "flex-start"}>
							{STATION_EQUIPMENT_OPTIONS.map((equipment) => (
								<Checkbox
									key={form.key(equipment.value)}
									{...form.getInputProps(equipment.value, {
										type: "checkbox",
									})}
									label={equipment.label}
									size="md"
								/>
							))}
						</Group>
					</Grid.Col>

					<Grid.Col span={12}>
						<Divider />
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<StationVehiclesInput form={form} />
					</Grid.Col>

					<Grid.Col span={12}>
						<Checkbox
							key={form.key("isPoint")}
							{...form.getInputProps("isPoint", { type: "checkbox" })}
							label="Station als ein Punkt setzen"
							size="md"
						/>
					</Grid.Col>

					<Grid.Col span={12}>
						<Checkbox
							key={form.key("isGenerationEmailsToMsps")}
							{...form.getInputProps("isGeneratingEmailsToMsps", {
								type: "checkbox",
							})}
							label="E-Mails an MSPs für Kontingentüberschreitungen generieren"
							size="md"
						/>
					</Grid.Col>

					<Grid.Col span={12}>
						<Divider />
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<Select
							key={form.key("state")}
							{...form.getInputProps("state")}
							onChange={(value) => {
								if (value) {
									form.setFieldValue("state", value);
									setIsPlannedClosureCheckboxEnabled(
										value === StationState.Active
									);
									if (value === StationState.Active) {
										form.setFieldValue("stateComment", null);
									}
								}
							}}
							label="Status"
							withAsterisk
							allowDeselect={false}
							placeholder="Bitte wähle den Status der Station aus"
							data={stationStateOptions(!!station)}
							disabled={isPlannedClosureEnabled}
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<Textarea
							key={form.key("stateComment")}
							{...form.getInputProps("stateComment")}
							label="Kommentar zum Status (max. 255 Zeichen)"
							maxLength={255}
							autosize
							placeholder="Gib einen Kommentar zum Status der Station an, wenn gewünscht"
							disabled={
								isPlannedClosureEnabled ||
								form.getValues().state === StationState.Active
							}
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<Stack>
							<Checkbox
								key={form.key("plannedClosures.enabled")}
								{...form.getInputProps("plannedClosures.enabled", {
									type: "checkbox",
								})}
								onChange={({ target: { checked } }) => {
									form.setFieldValue("plannedClosures.enabled", checked);
									setIsPlannedClosureEnabled(checked);
									if (!checked) {
										form.setFieldValue("state", StationState.Active);
										form.setFieldValue("stateComment", null);
									}
								}}
								label="Geplante Sperrungen aktivieren"
								disabled={!isPlannedClosureCheckboxEnabled}
								size="md"
							/>
							<Text size="sm" c={isPlannedClosureEnabled ? "" : "dimmed"}>
								Füge geplante Sperrzeiten hinzu, um den Status zwischen “Aktiv”
								und “Vorübergehend gesperrt” vom System schalten zu lassen.
							</Text>
							<Button
								className={classes["planned-closures-button"]}
								variant="default"
								fullWidth={isMobile}
								onClick={() =>
									form.insertListItem("plannedClosuresList", { comment: null })
								}
								disabled={!isPlannedClosureEnabled}
							>
								+ Sperrzeit hinzufügen
							</Button>
						</Stack>
					</Grid.Col>

					<Grid.Col span={12}>
						<PlannedClosuresListInput
							form={form}
							isPlannedClosureEnabled={isPlannedClosureEnabled}
						/>
					</Grid.Col>

					{isMobile && (
						<Grid.Col span={12}>
							<Divider />
						</Grid.Col>
					)}

					<Grid.Col span={12}>
						<Flex
							gap="md"
							justify="flex-end"
							align="flex-end"
							direction={isMobile ? "column-reverse" : "row"}
						>
							<Link
								to=".."
								relative="path"
								style={isMobile ? { width: "100%" } : {}}
							>
								<Button variant="outline" fullWidth={isMobile}>
									Abbrechen
								</Button>
							</Link>
							<Button
								type="submit"
								fullWidth={isMobile}
								disabled={!isSaveButtonEnabled}
							>
								Speichern
							</Button>
						</Flex>
					</Grid.Col>
				</Grid>
			</form>
		</>
	);
}

export default StationForm;
